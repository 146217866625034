import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";

function PrivacyPolicy() {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  const location = useLocation();
  const currentURL = `https://bangla-news.live/${location.pathname}`;

  return (
    <>
      <div className="Apps">
        <Helmet>
          <link rel="canonical" href={currentURL} />
          <title>
            Privacy Policy | Bangla News Live | ABP Ananda, News18 Bangla,
            Calcutta News, TV9 Bangla, Republic Bangla, Kolkata TV, Zee 24
            Ghanta
          </title>
          <meta
            name="description"
            content="Read the privacy policy for www.bangla-news.live, covering cookie use, data protection, and third-party links. Watch live Bengali news from ABP Ananda, News18 Bangla & more."
          />
          <meta
            name="keywords"
            content="Privacy policy, Bangla news live, Bengali news channels, ABP Ananda live, News18 Bangla live, TV9 Bangla live, Republic Bangla live, Kolkata TV live, Zee 24 Ghanta live, privacy protection, data usage, cookie policy"
          />
        </Helmet>
        <Header />

        <div className="privacy-policy">
          <div className="container">
            <button className="back-arrow" onClick={goBack}>
              <i className="fa fa-long-arrow-left"></i>
            </button>
            <h1>Privacy Policy</h1>
          </div>

          <p>
            Welcome to Bangla News Live, accessible at www.bangla-news.live.
            Your privacy is of utmost importance to us, and this policy explains
            how we collect, use, and protect your personal data. By using our
            website, you agree to the terms outlined below.
          </p>
          <ol className="bullet-list">
            <li>
              <h2>Information We Collect</h2>
              <p>
                We do not collect personal information unless you voluntarily
                provide it, such as when you contact us through email. However,
                we may collect non-personal data such as your IP address,
                browser type, and viewing preferences, which helps us improve
                our services.
              </p>
            </li>
            <li>
              <h2>Third-Party Content and Links</h2>
              <p>
                Our website provides live streaming of Bengali news channels via
                embedded YouTube links. These include:
              </p>
              <ul className="dash-list">
                <li>
                  ABP Ananda – Watch live at [ABP Ananda Live] (
                  <h3>
                    <a
                      href="https://www.youtube.com/watch?v=PKwLsAu-z10"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.youtube.com/watch?v=PKwLsAu-z10
                    </a>
                  </h3>
                  )
                </li>
                <li>
                  News18 Bangla – Watch live at [News18 Bangla Live] (
                  <h3>
                    <a
                      href="https://www.youtube.com/watch?v=6Ec7bnQVO44"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.youtube.com/watch?v=6Ec7bnQVO44
                    </a>
                  </h3>
                  )
                </li>
                <li>
                  Calcutta News – Watch live at [Calcutta News Live] (
                  <h3>
                    <a
                      href="https://www.youtube.com/watch?v=_ZcqAXauhIU"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.youtube.com/watch?v=_ZcqAXauhIU
                    </a>
                  </h3>
                  )
                </li>
                <li>
                  TV9 Bangla – Watch live at [TV9 Bangla Live] (
                  <h3>
                    <a
                      href="https://www.youtube.com/watch?v=F4nH4PeX_7w"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.youtube.com/watch?v=F4nH4PeX_7w
                    </a>
                  </h3>
                  )
                </li>
                <li>
                  Republic Bangla – Watch live at [Republic Bangla Live] (
                  <h3>
                    <a
                      href="https://www.youtube.com/watch?v=pivpyRNKZvo"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.youtube.com/watch?v=pivpyRNKZvo
                    </a>
                  </h3>
                  )
                </li>
                <li>
                  Kolkata TV – Watch live at [Kolkata TV Live] (
                  <h3>
                    <a
                      href="https://www.youtube.com/watch?v=DftPx6fmjTw"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.youtube.com/watch?v=DftPx6fmjTw
                    </a>
                  </h3>
                  )
                </li>
                <li>
                  Zee 24 Ghanta – Watch live at [Zee 24 Ghanta Live] (
                  <h3>
                    <a
                      href="https://www.youtube.com/watch?v=8aApFBmEJV0"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.youtube.com/watch?v=8aApFBmEJV0
                    </a>
                  </h3>
                  )
                </li>
              </ul>
              <p>
                All video content belongs to the respective owners, and Bangla
                News Live does not claim ownership. We only embed links for easy
                access to live Bengali news.
              </p>
            </li>
            <li>
              <h2>Cookies</h2>
              <p>
                We use cookies to enhance your experience on our website.
                Cookies help us understand how visitors interact with the site,
                allowing us to improve user experience. You can disable cookies
                through your browser settings.
              </p>
            </li>
            <li>
              <h2>Data Protection</h2>
              <p>
                We prioritize your privacy and ensure that no sensitive personal
                data is stored on our website. Our platform is designed with
                security measures to protect your browsing experience.
              </p>
            </li>
            <li>
              <h3>External Links</h3>
              <p>
                Our website contains links to external sources (YouTube). When
                you click these links, you are subject to their privacy
                policies. We recommend reviewing YouTube’s privacy policy for
                more details.
              </p>
            </li>
            <li>
              <h2>Contact Us</h2>
              <p>
                For any questions or concerns about our privacy policy, feel
                free to reach out at contact@bangla-news.live.
              </p>
            </li>
          </ol>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default PrivacyPolicy;
